import React, { useState } from 'react';
import '../../assets/styles/product.scss'

import image1 from '../../assets/images/products/products-image-1.jpg';
import image2 from '../../assets/images/products/products-image-2.jpg';
import image3 from '../../assets/images/products/products-image-3.jpg';
import image5 from '../../assets/images/products/products-image-5.jpg';
import arrowLeft from '../../assets/images/products/products-arrow-left.svg';
import arrowRight from '../../assets/images/products/products-arrow-right.svg';

const Products = () => {
  const [index, setIndex] = useState(0);
  const RELATIVE_URL = 'https://pottencial.com.br'

  const items = [
    {
      title: 'Seguro Garantia',
      description:
        'Garante que você receberá o que foi prometido em um contrato, caso ele seja descumprido.',
      image: image1,
      link: `${RELATIVE_URL}/seguro/seguro-garantia`,
    },
    {
      title: 'Seguro Garantia Judicial',
      description:
        'Modalidade de seguro específica para processos judiciais. Possui ampla aplicação como alternativa ao pagamento de cauções, depósitos judiciais em dinheiro, penhora de bens e fianças bancárias.',
      image: image2,
      link: `${RELATIVE_URL}/seguro/seguro-garantia-judicial`,
    },
    {
      title: 'Seguro Garantia Judicial Depósito Recursal',
      description:
        'Modalidade do Seguro Garantia que permite a substituição dos depósitos judiciais, nos recursos da justiça do trabalho, por uma apólice de seguros. Está previsto na CLT e tem grande facilidade de contratação.',
      image: image3,
      link: `${RELATIVE_URL}/seguro/seguro-garantia-judicial-deposito-recursal`,
    },
    {
      title: 'Pottencial Máquinas e Equipamentos',
      description:
        'Seguro desenvolvido para garantir a sua tranquilidade, protegendo os mais diversos tipos de equipamentos, seja qual for o seu negócio ou atividade da sua empresa – agronegócio, indústria ou serviços.',
      image: image5,
      link: `${RELATIVE_URL}/seguro/pottencial-maquinas-e-equipamentos`,
    },
  ];

  return (
    <section className="section section-products" id="section-products">
      <div className="container">
        <h2 className="section-title">Conheça nossos outros produtos</h2>

        <div className="flex-wrapper">
          <div className="count">
            <ul>
              {items.map((item, number) => (
                <li
                  className={index === number ? 'active' : ''}
                  data-index={number}
                  key={number}
                  onClick={() => setIndex(number)}
                >
                  <button
                    type="button"
                    title="Ver conteúdo"
                    aria-label="Ver conteúdo"
                  >
                    0{number + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="images">
            {items.map((item, number) => (
              <div
                className={index === number ? 'image active' : 'image'}
                data-index={number}
                key={number}
              >
                <img src={item.image} alt="Imagem - Pottencial Seguradora" />
              </div>
            ))}
          </div>

          <div className="tabs">
            {items.map((item, number) => (
              <div
                className={
                  index === number ? 'tab-content active' : 'tab-content'
                }
                data-index={number}
                key={number}
              >
                <h3 className="tab-content--title">{item.title}</h3>

                <div className="tab-content--description">
                  <p>{item.description}</p>
                </div>

                <div className="tab-content--cta">
                  <a
                    href={item.link}
                    title="Ir para a página"
                    aria-label="Ir para a página"
                  >
                    + Saiba mais
                  </a>
                </div>
              </div>
            ))}

            <div className="arrows">
              <button
                type="button"
                title="Voltar slide"
                aria-label="Voltar slide"
                onClick={() =>
                  index === 0 ? setIndex(items.length - 1) : setIndex(Number(index) - 1)
                }
              >
                <img src={arrowLeft} alt="Ícone - Pottencial Seguradora" />
              </button>

              <button
                type="button"
                title="Avançar slide"
                aria-label="Avançar slide"
                onClick={() =>
                  index === items.length - 1 ? setIndex(0) : setIndex(Number(index) + 1)
                }
              >
                <img src={arrowRight} alt="Ícone - Pottencial Seguradora" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
