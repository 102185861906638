import React, { useState, useLayoutEffect, useEffect } from 'react';

const ReCaptcha = ({ name }) => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  useLayoutEffect(() => {
    const body = document.querySelector('body');
    body.addEventListener('accepted', function () {
      setTimeout(
        () =>
          document.querySelector('.form-submit').classList.remove('disabled'),
        1000
      );
      setAcceptedCookies(true);
    });
  }, []);

  useEffect(() => {
    const cookies = document.cookie;

    if (cookies.includes('accepted-cookie-policy=true')) {
      setTimeout(
        () =>
          document.querySelector('.form-submit').classList.remove('disabled'),
        1000
      );
      setAcceptedCookies(true);
    }
  }, []);

  useEffect(() => {
    if (acceptedCookies) {
      setTimeout(function () {
        if(window.grecaptcha){
          window.grecaptcha.render(`g-recaptcha-${name}`, {
            sitekey: window.ENV.CAPTCHA_KEY,
          });
        }        
      }, 2000);
    }
  }, [acceptedCookies]);

  return (
    <div className="field-group">
      {acceptedCookies ? (        
        <div id={`g-recaptcha-${name}`}></div>
      ) : (
        <div className="accept-cookies">
          <p>
            Para prosseguir você precisa aceitar nossa{' '}
            <a
              href="https://stdevsiteinst01.blob.core.windows.net/sitefiles/Governanca/Politica-cookies-v001.pdf"
              title="Ir para a página de política de cookies"
              aria-label="Ir para a página de política de cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Cookies
            </a>
            .
          </p>
        </div>
      )}
    </div>
  );
};

export default ReCaptcha;
