import React from 'react';

const InputText = ({
  type,
  name,
  label,
  icon = false,
  value,
  handleChange,
  handleBlur,
  error,
}) => (
  <div
    className={error ? 'field-group invalid' : 'field-group'}
    data-name={name}
    data-type="text"
  >
    <div className="label">{label}</div>

    <input
      className="field"
      type={type}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />

    <span className={error ? 'active error' : 'error'}>{error && error}</span>

    {icon && <img src={icon} alt="Ícone- Pottencial" />}
  </div>
);

export default InputText;
