import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({
  tag,
  title,
  label,
  href = false,
  anchor = false,
  type = false,
  className = false,
  onClick = false,
}) => {
  const TagButton = () => {
    if (tag === 'anchor' && anchor) {
      return (
        <a
          href={anchor}
          className={className ? 'button ' + className : 'button button-orange'}
          title={label}
          aria-label={label}
        >
          {title}
        </a>
      );
    } else if (tag === 'link' && href) {
      return (
        <Link
          to={href}
          className={className ? 'button ' + className : 'button button-orange'}
          title={label}
          aria-label={label}
        >
          {title}
        </Link>
      );
    } else if (tag === 'button' && type) {
      return onClick ? (
        <button
          type={type}
          className={className ? 'button ' + className : 'button button-orange'}
          title={label}
          aria-label={label}
          onClick={onClick}
        >
          {title}
        </button>
      ) : (
        <button
          type={type}
          className={className ? 'button ' + className : 'button button-orange'}
          title={label}
          aria-label={label}
        >
          {title}
        </button>
      );
    } else {
      return '';
    }
  };

  return <TagButton />;
};

export default Button;
