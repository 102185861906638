
import React from 'react';
import '../../assets/styles/form.scss'
import '../../assets/styles/progress-screen.scss'
import { Formik } from 'formik';
import axios from 'axios';
import {
  useInputText,
  useRadio,
  progressScreen
} from '../../utils/general';

import Button from '../../components/core/Button';
import InputText from '../../components/core/Form/InputText';
import ReCaptcha from '../../components/core/Form/ReCaptcha';
import Radio from '../../components/core/Form/Radio';

const ContactForm = () => {
  useInputText();
  useRadio();

  return (
    <Formik
      initialValues={{
        nome: '',
        email: '',
        telefone: '',
        contato: 'email',
      }}
      validate={(values) => {
        const errors = {};

        if (!values.nome) {
          errors.nome = 'Campo obrigatório';
        } else if (!values.email) {
          errors.email = 'Campo obrigatório';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'E-mail inválido';
        } else if (!values.telefone) {
          errors.telefone = 'Campo obrigatório';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        const contato = document.querySelector(
          '.field-group[data-name="contato"] .options .option.active button'
        ).textContent;
        if (!contato) return;

        values.contato = contato;

        progressScreen('create', 'Enviando mensagem...');

        if (window.grecaptcha?.getResponse().length === 0) {
          setTimeout(
            () =>
              progressScreen(
                'close',
                'Por favor, verifique que você não é um robô...'
              ),
            1000
          );
          return;
        }

        const stringBody =
          `Olá! Essa mensagem foi encaminhada através de um formulário da Conecta Imobi, do site Pottencial\n\n` +
          `Nome: ${values.nome}\n` +
          `Email: ${values.email}\n` +
          `Telefone: ${values.telefone}\n` +
          `Preferência de contato: ${values.contato}\n` +
          ``;

        axios
          .post(
            `${window.ENV.BASE_API_URL_ENV}/${window.ENV.EMAIL_PATH}`,
            {
              to: 'atendimento@pottencial.com.br',
              subject: 'Contato Conecta Imobi',
              body: stringBody,
            }
          )
          .then((res) => {
            resetForm({});

            setTimeout(function () {
              progressScreen(
                'change',
                'Mensagem enviada com sucesso...'
              );

              setTimeout(
                () =>
                  progressScreen(
                    'close',
                    'Olá! É um prazer tê-lo conosco! Nossa equipe de Relacionamento entrará em contato com você através do e-mail cadastrado. Fique atento.'
                  ),
                2000
              );
             }, 1000);
          })
          .catch((error) => {
            setTimeout(
              () =>
                progressScreen(
                  'close',
                  'Houve algum erro, contate o administrador...'
                ),
              1000
            );
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
          <form onSubmit={handleSubmit}>

            <InputText
              type="text"
              name="nome"
              label="Nome"
              value={values.nome}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.nome && touched.nome && errors.nome}
            />
            <div className="row">
              <div className="col-md-6">
                <InputText
                  type="email"
                  name="email"
                  label="E-mail"
                  value={values.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.email && touched.email && errors.email}
                />
              </div>
              <div className="col-md-6">
                <InputText
                  type="tel"
                  name="telefone"
                  label="Telefone"
                  value={values.telefone}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.telefone && touched.telefone && errors.telefone}
                />
              </div>
            </div>

            <Radio
              name="contato"
              label="Como você prefere ser contatado?"
              value={values.contato}
              options={[
                {
                  title: 'E-mail',
                },
                {
                  title: 'Telefone',
                },
                {
                  title: 'Whatsapp',
                },
              ]}
            />
            <div className="field-group">
              <ReCaptcha name="page-contact" />
            </div>
            <div className="field-group">
              <div className="form-submit" disabled={isSubmitting}>
                <Button
                  type="submit"
                  tag="button"
                  title="Enviar"
                  label="Enviar"
                />
              </div>
            </div>
          </form>
        )}
    </Formik>
  );
};

export default ContactForm;
