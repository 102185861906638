import { useEffect } from 'react';

export const useInputText = () => {
  useEffect(() => {
    const groups = document.querySelectorAll('.field-group[data-type="text"]');

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const field = group.querySelector('.field');

      field.addEventListener('focus', () => group.classList.add('focus'));
      field.addEventListener('keyup', () => group.classList.add('focus'));

      field.addEventListener('blur', () => {
        group.classList.remove('focus');
        if (field.value.length === 0) {
          group.classList.remove('not-empty');
        } else {
          group.classList.add('not-empty');
        }
      });
    }
  }, []);
};

export const useInputFile = () => {
  useEffect(() => {
    const groups = document.querySelectorAll('.field-group[data-type="file"]');

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const field = group.querySelector('.field');
      const filename = group.querySelector('.filename');

      field.addEventListener('change', () => {
        let title = field.value.split('\\').slice(-1)[0];

        if (title.length === 0) {
          group.classList.remove('not-empty');
          filename.innerHTML = 'Nenhum arquivo selecionado';
        } else {
          if (title.length > 25) title = title.substr(0, 25) + '...';

          filename.innerHTML = 'Arquivo: ' + title;
          group.classList.add('not-empty');
        }
      });
    }
  }, []);
};

export const useSelect = () => {
  useEffect(() => {
    const selects = document.querySelectorAll('.field-group .select');

    for (let i = 0; i < selects.length; i++) {
      const select = selects[i];
      const group = select.parentElement;
      const field = select.querySelector('.field');
      const box = select.querySelector('.select-box');
      const dropdown = select.querySelector('.select-dropdown');
      const buttons = dropdown.querySelectorAll('button');

      box.addEventListener('click', () =>
        group.classList.contains('active')
          ? group.classList.remove('active')
          : group.classList.add('active')
      );

      for (let y = 0; y < buttons.length; y++) {
        const button = buttons[y];
        const text = button.innerHTML;

        button.addEventListener('click', function () {
          box.querySelector('span').innerHTML = text;
          field.value = text;
          group.classList.remove('active');
          group.classList.add('not-empty');
          group.dispatchEvent(new Event('selected'));
        });
      }

      document.addEventListener('click', function (e) {
        if (e.target.contains(group) && group.classList.contains('active'))
          group.classList.remove('active');
      });
    }
  }, []);
};

export const useRadio = () => {
  useEffect(() => {
    const groups = document.querySelectorAll(
      '.field-group[data-type="options"]'
    );

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const field = group.querySelector('.field');
      const options = group.querySelectorAll('.options .option');

      for (let x = 0; x < options.length; x++) {
        const option = options[x];
        const button = option.querySelector('button');

        button.addEventListener('click', function () {
          const text = button.getAttribute('data-value');

          field.value = text;

          for (let y = 0; y < options.length; y++) {
            const option = options[y];
            option.classList.remove('active');
          }

          option.classList.add('active');
        });
      }
    }
  }, []);
};

export const progressScreen = (option, message) => {
  const body = document.querySelector('body');

  if (option === 'create') {
    const screenHtml = document.createElement('div');
    const screenHtmlMessage = document.createElement('div');
    const screenHtmlSpinner = document.createElement('div');
    const screenHtmlSpinnerSpan1 = document.createElement('span');
    const screenHtmlSpinnerSpan2 = document.createElement('span');
    screenHtml.classList.add('screen-progress');
    screenHtmlMessage.classList.add('screen-message');
    screenHtmlMessage.innerHTML = message;
    screenHtmlSpinner.classList.add('spinner');
    screenHtmlSpinner.appendChild(screenHtmlSpinnerSpan1);
    screenHtmlSpinner.appendChild(screenHtmlSpinnerSpan2);
    screenHtml.appendChild(screenHtmlMessage);
    screenHtml.appendChild(screenHtmlSpinner);

    body.classList.add('shadow');
    body.appendChild(screenHtml);
    setTimeout(() => screenHtml.classList.add('open'), 300);
  } else {
    const screen = document.querySelector('.screen-progress');
    const screenMessage = screen.querySelector('.screen-message');

    if (option === 'change') {
      screenMessage.innerHTML = message;
    } else if (option === 'close') {
      screenMessage.innerHTML = message;

      setTimeout(
        function () {
          screen.classList.remove('open');

          setTimeout(() => body.classList.remove('shadow'), 500);
          setTimeout(() => screen.remove(), 1000);
        },
        message.includes('Olá') ? 6000 : 1000
      );
    }
  }
};
