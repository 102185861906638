import React from 'react'
import InsuranceItem from './InsuranceItem'
import '../../assets/styles/insurance.scss'

import imgInsurance1 from '../../assets/images/img-insurance1.png'
import imgInsurance2 from '../../assets/images/img-insurance2.png'
import imgInsurance3 from '../../assets/images/img-insurance3.png'

const Insurance = () => {
  const items = [
    {
      title: "Pottencial Aluguel",
      desc: "É o seguro Fiança Locatícia da Pottencial. Modalidade de seguro que substitui outras garantias de pagamento de aluguel e que protege o proprietário de um imóvel em caso de não pagamento de aluguel e encargos por parte do inquilino.",
      image: imgInsurance1,
      url: "https://pottencial.com.br/seguro/pottencial-aluguel",
      position: "left",
      subtitle: "left"
    },
    {
      title: "Pottencial Imobiliário",
      desc: "É o Seguro Incêndio, feito para as imobiliárias e destinado às locações residenciais, protegendo o patrimônio do proprietário e oferecendo tranquilidade ao inquilino.",
      image: imgInsurance2,
      url: "https://pottencial.com.br/",
      position: "right",
      subtitle: "right"
    },
    {
      title: "Pottencial Riscos de Engenharia - Reformas",
      desc: "O Seguro Pottencial Riscos de Engenharia Reformas, oferece de forma ampla a proteção contra os prejuízos à obra que podem ocorrer durante a execução dos projetos.",
      image: imgInsurance3,
      url: "https://pottencial.com.br/seguro/riscos-de-engenharia",
      position: "left",
      subtitle: "right"
    }
  ]
  return <section id="section-insurance">
    <div className="container">
      <h2 className="title">Conheça nossos seguros para o setor Imobiliário </h2>
      {
        items.map((item, index) => <InsuranceItem key={index} item={item} />)
      }
    </div>
  </section>
}

export default Insurance