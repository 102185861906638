import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main'

function App() {

  return (
    <main>
      <Header />
      <Main />
      <Footer />
    </main>
  );
}

export default App;
