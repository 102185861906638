import React from 'react'
import Products from './Products'
import Contact from './Contact'
import Insurance from './Insurance'

const Main = () => (
  <>
    <Insurance />
    <Products />
    <Contact />
  </>
)

export default Main