import React from 'react';

const Radio = ({ name, label, value, options }) => (
  <div className="field-group" data-name={name} data-type="options">
    <input type="hidden" className="field" name={name} value={value} />
    <div className="placeholder">{label}</div>

    <div className="options">
      {options.map((item, index) => (
        <div
          className={index === 0 ? 'option active' : 'option'}
          key={`${name}-radio-${index}`}
        >
          <button
            type="button"
            title="Escolher opção"
            aria-label="Escolher opção"
            data-value={item.title}
          >
            {item.title}
          </button>
        </div>
      ))}
    </div>
  </div>
);

export default Radio;
