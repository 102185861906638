import React, { useState } from 'react'
import '../../assets/styles/header.scss'
import '../../assets/styles/menu.scss'
import logo from '../../assets/images/logo.svg'
import iconPhone from '../../assets/images/header-phone.svg';
import iconClose from '../../assets/images/menu-close.svg';
import iconMenuPhone from '../../assets/images/menu-phone.svg';
import iconMenuToggler from '../../assets/images/menu-toggler.svg';
import iconArrowDown from '../../assets/images/menu-arrow-down.svg';
import arrowTop from '../../assets/images/home/hero-arrow.svg';
import icon from '../../assets/images/general/cookie-icon.svg';

const Header = () => {
  const [activeMenu, setActiveMenu] = useState();
  const [accepted, setAccepted] = useState(false);

  const loadScripts = () => {
    const body = document.querySelector('body');
    const scripts = document.createElement('script');
    const googleTagManager = document.createElement('script');
    const googleRecaptcha = document.createElement('script');
    const blipChat = document.createElement('script');

    scripts.src = `/scripts.js`;
    // scripts.type = "text/babel";
    scripts.async = true;

    googleTagManager.src =
      'https://www.googletagmanager.com/gtag/js?id=UA-105105988-1';
    googleTagManager.async = true;

    googleRecaptcha.src = 'https://www.google.com/recaptcha/api.js';
    googleRecaptcha.async = true;

    blipChat.src = 'https://unpkg.com/blip-chat-widget';
    blipChat.async = true;

    // document.querySelector('footer').classList.add('no-cookie');
    document.body.appendChild(googleTagManager);
    document.body.appendChild(googleRecaptcha);
    document.body.appendChild(blipChat);
    body.dispatchEvent(new Event('accepted'));
    setTimeout(() => document.body.appendChild(scripts), 2000);
  };

  const acceptCookies = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 3);

    if (window.innerWidth <= 1024) {
      document.querySelector('#main-cta-whatsapp').style.bottom = '110px';
    }
    document.cookie = `accepted-cookie-policy=true; expires=${expiryDate}; Path=/;`;
    setAccepted(true);
    loadScripts();
  };

  const useEffect = (() => {
    const cookies = document.cookie;
    const cookieHeight = document.querySelector('#main-cookie-policy')
      .offsetHeight;

    if (cookies.includes('accepted-cookie-policy=true')) {
      if (window.innerWidth <= 1024) {
        document.querySelector('#main-cta-whatsapp').style.bottom = '110px';
      }
      loadScripts();
    } else {
      setAccepted(false);
      if (window.innerWidth <= 1024) {
        document.querySelector('#main-cta-whatsapp').style.bottom =
          cookieHeight + 60 + 'px';
      }
    }

    window.addEventListener('scroll', function () {
      const headerHeight = document.querySelector('header').offsetHeight;
      const footerHeight = document.querySelector('footer').offsetHeight;
      const cookieHeight = document.querySelector('#main-cookie-policy')
        .offsetHeight;
      const maxHeight = document.body.scrollHeight - window.innerHeight;
      const scrollOffset = window.scrollY - headerHeight;

      if (scrollOffset >= maxHeight - footerHeight) {
        document.querySelector('#main-cookie-policy').classList.add('fixed');
        document.querySelector('#main-cookie-policy').style.top =
          document.body.scrollHeight - footerHeight - cookieHeight + 40 + 'px';
      } else {
        document.querySelector('#main-cookie-policy').classList.remove('fixed');
        document.querySelector('#main-cookie-policy').removeAttribute('style');
      }
    });
  }, []);

  const RELATIVE_URL = 'https://pottencial.com.br'

  const menu1 = [
    {
      title: 'Sobre Nós',
      link: `${RELATIVE_URL}/sobre`,
    },
    {
      title: 'Transparência e Privacidade',
      link: `${RELATIVE_URL}/transparenciaeprivacidade`,
    },
    {
      title: 'Certidões Legais',
      link: `${RELATIVE_URL}/certidoes-legais`,
    }
  ];

  const menu2 = [
    {
      title: 'Seguro Garantia',
      link: `${RELATIVE_URL}/seguro/seguro-garantia`,
    },
    {
      title: 'Seguro Garantia Judicial',
      link: `${RELATIVE_URL}/seguro/seguro-garantia-judicial`,
    },
    {
      title: 'Seguro Garantia Judicial Depósito Recursal',
      link: `${RELATIVE_URL}/seguro/seguro-garantia-judicial-deposito-recursal`,
    },
    {
      title: 'Pottencial Aluguel',
      link: `${RELATIVE_URL}/seguro/pottencial-aluguel`,
    },
    {
      title: 'Pottencial Máquinas e Equipamentos',
      link: `${RELATIVE_URL}/seguro/pottencial-maquinas-e-equipamentos`,
    },
    {
      title: 'Pottencial Riscos de Engenharia',
      link: `${RELATIVE_URL}/seguro/riscos-de-engenharia`,
    },
  ];

  /*loadScripts();*/
  /*console.log(accepted);*/
  return (
    <>
      <header id="main-header">
        <div id="footer">
          <div id="main-cookie-policy" className={accepted ? '' : 'active'}>
            <div className="cookie-content">
              <div className="cookie-content--icon">
                <img src={icon} alt="Imagem - Pottencial" />
              </div>

              <div className="cookie-content--description">
                <p>
                  Nós usamos cookies e outras tecnologias semelhantes para melhorar
                  sua experiência, personalizar publicidade e recomendar conteúdo de
                  seu interesse. Para continuar navegando, você deve concordar com
            este monitoramento. Para mais informações, consulte a nossa {''}
                  <a
                    href="https://stdevsiteinst01.blob.core.windows.net/sitefiles/Governanca/Politica-cookies-v001.pdf"
                    title="Ir para a página de política de cookies"
                    aria-label="Ir para a página de política de cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de Cookies
            </a>
            .
          </p>
              </div>

              <div className="cookie-content--cta">
                <button
                  type="buttom"
                  className="button button-orange"
                  title="Aceitar"
                  aria-label="Aceitar"
                  onClick={() => acceptCookies()}
                >
                  Aceitar
          </button>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="flex-wrapper">
              <div className="logo">
                <a
                  href="/"
                  title="Ir para a página principal"
                  aria-label="Ir para a página principal"
                >
                  <img src={logo} alt="Logo - Pottencial Seguradora" />
                </a>
              </div>

              <div className="right">
                <div className="navigation">
                  <ul>
                    <li className="has-children">
                      <span>Sobre</span>
                      <img src={iconArrowDown} alt="Ícone - Pottencial" />

                      <div className="submenu">
                        <ul>
                          {menu1.map((item, index) => (
                            <li key={`submenu-1-${index}`}>
                              <a
                                href={item.link}
                                title="Ir para a página"
                                aria-label="Ir para a página"
                              >
                                {item.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>

                    <li className="has-children">
                      <span>Seguros</span>
                      <img src={iconArrowDown} alt="Ícone - Pottencial" />

                      <div className="submenu">
                        <ul>
                          {menu2.map((item, index) => (
                            <li key={`submenu-2-${index}`}>
                              <a
                                href={item.link}
                                title="Ir para a página"
                                aria-label="Ir para a página"
                              >
                                {item.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>

                    <li>
                      <a
                        href="https://pottencial.com.br/consultar-apolice"
                        title="Ir para a página de consultar a apólice"
                        aria-label="Ir para a página de consultar a apólice"
                      >
                        Consultar Apólice
                    </a>
                    </li>

                    <li>
                      <a
                        href="http://blog.pottencial.com.br/"
                        title="Ir para a página de blog"
                        aria-label="Ir para a página de blog"
                      >
                        Blog
                    </a>
                    </li>
                  </ul>
                </div>

                <div className="phones">
                  <img src={iconPhone} alt="Ícone - Pottencial Seguradora" />

                  <a
                    href="tel:553121217777"
                    title="Ligar para esse número"
                    aria-label="Ligar para esse número"
                  >
                    (31) 2121-7777
                </a>

                  <a
                    href="tel:08006067688"
                    title="Ligar para esse número"
                    aria-label="Ligar para esse número"
                  >
                    0800 606 7688
                </a>
                </div>
              </div>

              <button
                type="button"
                className="menu-toggler"
                title="Abrir menu"
                aria-label="Abrir menu"
                onClick={() => setActiveMenu(true)}
              >
                <img src={iconMenuToggler} alt="Ícone - Pottencial Seguradora" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <div id="main-menu" className={activeMenu ? 'active' : ''}>
        <button
          type="button"
          className="close"
          title="Fechar menu"
          aria-label="Fechar menu"
          onClick={() => setActiveMenu(false)}
        >
          <img src={iconClose} alt="Ícone - Pottencial Seguradora" />
        </button>

        <div className="wrapper">
          <div className="navigation">
            <ul>
              <li className="active">
                <a
                  href="https://www.pottencial.com.br/"
                  title="Ir para a página principal"
                  aria-label="Ir para a página principal"
                >
                  Home
                </a>
              </li>

              <li>
                <a
                  href="/sobre"
                  title="Ir para a página sobre"
                  aria-label="Ir para a página sobre"
                >
                  Sobre
                </a>
              </li>

              <li>
                <span>Seguros</span>

                <ul>
                  {menu2.map((item, index) => (
                    <li key={`submenu-2-${index}`}>
                      <a
                        href={item.link}
                        title="Ir para a página"
                        aria-label="Ir para a página"
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>

              <li>
                <a
                  href="https://pottencial.com.br/consultar-apolice"
                  title="Ir para a página de consultar a apólice"
                  aria-label="Ir para a página de consultar a apólice"
                >
                  Consultar Apólice
                    </a>
              </li>

              <li>
                <a
                  href="http://blog.pottencial.com.br/"
                  title="Ir para a página de blog"
                  aria-label="Ir para a página de blog"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>

          <div className="phones">
            <div className="phone">
              <a
                href="tel:553121217777"
                title="Ligar para esse número"
                aria-label="Ligar para esse número"
              >
                <img src={iconMenuPhone} alt="Ícone - Pottencial Seguradora" />
                (31) 2121-7777
              </a>
            </div>

            <div className="phone">
              <a
                href="tel:08006067688"
                title="Ligar para esse número"
                aria-label="Ligar para esse número"
              >
                <img src={iconMenuPhone} alt="Ícone - Pottencial Seguradora" />
                0800 606 7688
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="banner">
        <div className="container">
          <h1>Olá! Que bom que você esteve conosco no Conecta Imobi!</h1>
          <div className="arrow-top">
            <img src={arrowTop} alt="Ícone - Pottencial Seguradora" />
          </div>
        </div>
      </div>

    </>
  )
}

export default Header