import React from 'react'
import PropTypes from 'prop-types'
import '../../assets/styles/form.scss'
import arrow from '../../assets/images/products/products-arrow-right.svg'

const InsuranceItem = ({ item }) => {
  const { title, desc, image, url, position, subtitle } = item

  return <div className="insurance-item">
    <div className={`row ${position}`}>
      <div className="col-md-6">
        <img src={image} alt="" />
        <a className={`btn btn-primary ${subtitle}`} href={url}>
          {title}
          <img src={arrow} alt="" />
        </a>
      </div>
      <div className="col-md-6">
        <h3 className="title">{title}</h3>
        <div className="desc">
          <p>{desc}</p>
        </div>
      </div>
    </div>
  </div>
}
InsuranceItem.propTypes = {
  item: PropTypes.object.isRequired,
}

InsuranceItem.defaultProps = {}

export default InsuranceItem