import React from 'react';
import '../../assets/styles/form.scss'
import '../../assets/styles/contact.scss'
import ContactForm from './ContactForm'
import bgContact from '../../assets/images/bg-contato.png'

const Contacts = () => {

  return (
    <section id="section-contact">
      <div className="container">

        <div className="row bigSpacing main-content">
          <div className="col-md-6 left">
            <h3 className="title">Quero saber mais ou receber uma cotação</h3>
            <ContactForm />
          </div>
          <div className="col-md-6 d-md-block right">
            <div className="container-bg">
              <img src={bgContact} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
